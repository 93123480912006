import Enlang from './entries/en-US';
import Salang from './entries/ar_SA';


const AppLocale = {
  en: Enlang,
  ar: Salang,

};
export default AppLocale;
