import MainProvider from "./providers";

function App() {
  return (
    <>
      <MainProvider  />
    </>
  );
}

export default App;
